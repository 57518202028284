.inner-content {
  padding: 50px 0;

  h1 {
    font-size: 40px;
    line-height: 42px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 34px;
    margin: 15px 0;
  }

  h4 {
    font-size: 22px;
  }

  .btn {
    margin-top: 20px;
  }

  .alignleft {
    margin: 0 20px 5px 0;
  }
}



.contact {
  .footer-cta {
    display: none;
  }
}


#wpsl-wrap {
  margin-top: 30px;
}

.job-block {
  margin-top: 30px;
}


ul.job-list {
  padding: 0;
  list-style-type: none;
  border: 1px solid #ddd;
  border-bottom: 0;
  margin-top: 20px;

  a {
    display: block;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    font-size: 18px;
    transition: ease-in-out all .2s;

    &:hover {
      background-color: $yellow;
      color: $white;
    }
  }
}


.our-stores {

  #wpsl-gmap {
    .wpsl-infobox {
      font-weight: 700;
      font-size: 14px;


      strong {
        display: inline-block;
        margin-bottom: 10px;
      }
      a {
        font-weight: 400;
      }
    }
  }

  #wpsl-result-list {
    border: 1px solid #ccc;

    .wpsl-store-location {
      font-weight: 700;

      strong {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 18px;
      }

    }
  }

  #wpsl-stores {
    ul li {
      padding: 25px !important;
      cursor: pointer;
    }
  }

  .wpsl-default-filters {
    font-family: $primary-font;

    .wpsl-search {
      background-color: $white;
      color: $black;
      font-weight: 500;
      line-height: 25px;

      label[for="wpsl-search-input"] {
        width: 200px;
      }

      .wpsl-search-btn-wrap {
        #wpsl-search-btn {
          background-color: $yellow;
          background-image: none;
          color: $white;
          border: 0;
          width: 100px;
          transition: ease-in-out all .2s;
          line-height: 27px;

          &:hover {
            background-color: darken($yellow, 10);
          }

        }
      }

    }
  }
}

.error404-inner {
    padding-top: 170px;
    padding-bottom: 60px;
    background-color: $brand-primary;

    .block404 {
        padding: 50px;
        background-color: #efe7e6;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            border: 2px solid $brand-primary;
            margin: 15px 30px;
        }
    }
}

.external {
  &::after {
    color: #fff;
    content: "\f08e";
    font-family: "Font Awesome 5 Pro", serif;
    position: relative;
    right: -5px;
    font-size: 15px;
  }

  @include mobile {
    &.mobile-nav::after {
      right: -170px;
      bottom: 37px;
    }
  }
}

#menu-item-46 {
  @include mobile {
    margin-top: -23px;
  }
}

h3.results {
  color: $brand-primary;
  text-align: center;
  text-transform: uppercase;
  font-size: 26px;
}

table.job-listing {
  width: 100% !important;
  color: $brand-primary !important;

  tr,
  th,
  td {
    padding: 10px !important;
    vertical-align: top !important;
  }

  a.btn-primary.apply {
    padding: 8px 20px !important;
    border-radius: 5px !important;
  }

  p.btn-primary.share {
    padding: 4px 20px !important;
    border-radius: 5px !important;
    background-color: #d8a445 !important;
    margin-bottom: 0;
  }
}

th.hide-links,
td.hide-links {
  display: none;

  @include tablet {
    display: inline-block;
    padding-bottom: 40px;
  }
}

div.hide-links {
  display: none;
  padding-bottom: 0;
  margin-top: 10px;

  @include tablet {
    display: block;
  }

  @include laptop {
    display: inline-block;
    padding-bottom: 40px;
  }

  div.span {
    display: none;
    position: relative;
    margin-top: 10px;
    margin-left: 0;

    @include laptop {
      margin-left: -75px;
      position: absolute;
    }
  }
}

.hide-links:hover {
  div.span {
    display: block;
  }
}