.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;


  .logo-container {
    position: absolute;
    top: -130px;
    left: 15px;
    width: 250px;
    height: 250px;
    background-color: $white;
    border-radius: 50%;

    @include tablet {
      top: -130px;
      width: 250px;
      height: 250px;
    }

    @include desktop {
      top: -163px;
      width: 288px;
      height: 288px;
    }

    img.logo {
      width: 150px;
      position: absolute;
      bottom: 39px;
      left: 50%;
      transform: translateX(-50%);

      @include desktop {
        width: 162px;
      }
    }
  }

  // Global Nav Styles
  .nav-menu {
    text-align: right;
    margin: 0;

    li {
      display: inline-block;

      a {
        color: $white;
      }
    }

    .menu-item-has-children {
      position: relative;

      a {
        padding-bottom: 5px;

        &:hover {
          border: 0;
        }

        &:after {
          display: inline-block;
          font-family: FontAwesome;
          content: '\f107';
          font-weight: 400;
          font-size: 18px;
          line-height: 18px;
          padding-left: 5px;
          vertical-align: middle;
        }
      }

      &:hover {
        .sub-menu {
          max-height: 500px;
          transition: ease-in-out all .8s;
        }
      }


      .sub-menu {
        position: absolute;
        top: 29px;
        left: 0;
        text-align: left;
        padding: 0;
        margin: 0;
        max-height: 0;
        overflow: hidden;
        transition: ease-in-out all .2s;

        li {
          display: block;

          a {
            display: block;
            white-space: nowrap;
            font-size: 16px;
            background-color: rgba($brand-primary, .9);
            padding: 8px 20px;
            min-width: 125px;
            transition: ease-in-out all .2s;

            &:hover {
              border: 0;
              background-color: $yellow;
            }

            &:after {
              display: none;
            }
          }
        }
      }
    }

    // Styles for Top Nav
    &.top {
      margin: 12px 0 15px;

      li {
        padding-left: 16px;

        a {
          font-size: 16px;
          font-weight: 300;

          &:hover {
            border-bottom: 1px solid $white;
          }
        }
      }
    }

    // Styles for Primary Nav
    &.primary {

      li {
        padding-left: 15px;

        @include desktop {
          padding-left: 24px;
        }

        a {
          font-size: 17px;
          font-weight: 700;

          @include desktop {
            font-size: 18px;
          }

          &:hover {
            border-bottom: 2px solid $white;
          }
        }

        &.menu-item-has-children a:hover {
          border: 0;
        }
      }
    }
  }
}

.sideNav {

    .nav-stacked {
      position: relative;
      display: block;

      .hide-mobile {
        display: none;
      }


      .menu-item a {
        padding: 12px 20px;
        border-color: darken($brand-primary, 5);
        display: block;

        .fa {
          font-size: 14px;
          padding: 0 0 0 3px;
        }
      }

      .dropdown {
        a.dropdown-toggle::after {
          color: #fff;
        }
      }

      .dropdown-menu {
        padding: 0;
        margin: 0;
        position: relative;
        background-color: $brand-primary;
        border: 0px;
        float: none;

        a {
          padding: 12px 20px 12px 40px;
        }
      }

      .menu-item-has-children {
        > a {
          &:after {
            content: '';
          }
        }
      }
    }
}


.hero {
  height: 540px;
  position: relative;
  background-image: url('../images/hero_home.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 20%;

  @include tablet {
    height: 500px;
  }

  @include laptop {
    height: 747px;
  }

  &:before {
    content: '';
    @include stretch;
    background-color: rgba($brand-primary, .6);
  }

  .value-prop {
    text-align: center;
    margin-top: 200px;

    @include tablet {
      margin-top: 195px;
    }

    @include laptop {
      margin-top: 295px;
    }

    h1 {
      font-size: 50px;
      line-height: 50px;
      font-weight: 700;
      color: $white;

      @include laptop {
        font-size: 60px;
        line-height: 62px;
      }

      small {
        font-size: 40px;
        line-height: 38px;
        font-weight: 700;

        @include laptop {
          font-size: 50px;
        }
      }
    }

    .btn {
      margin: 27px 20px 0;
    }
  }
}


.strapline {
  background-color: $yellow;
  padding: 50px 0;
  position: relative;

  @include laptop {
    padding: 65px 0;
  }

  p {
    color: $white;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    margin: 0;

    @include laptop {
      font-size: 26px;
      line-height: 26px;
    }
  }
}


.interior-hero {
  height: 470px;
  position: relative;


  &:before {
    content: '';
    @include stretch;
    background-color: rgba($brand-primary, .6);
  }

  .value-prop {
    margin-top: 200px;
    text-align: center;

    @include tablet {
      margin-top: 220px;
    }

    h1 {
      color: $white;
      font-size: 40px;
      line-height: 42px;
      font-weight: 700;

      @include laptop {
        font-size: 50px;
        line-height: 52px;
      }
    }

    .btn {
      margin: 27px 20px 0;
    }
  }

  &.no-hero {
    height: 330px;
    position: relative;
    background-image: url('../images/hero_home.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 20%;

    .value-prop {
      margin-top: 170px;
    }
  }
}
