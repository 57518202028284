.footer-map-container {
  position: relative;
  padding: 0;
  color: $white;
  display: none;

  @include laptop {
    display: block;
  }


  .footer-map {
    #map_canvas {
      height: 500px;

      @include tablet {
        height: 650px;
      }
    }
  }


  .infoBox {
    width: 346px;
    min-height: 471px;
    background-color: rgba(#fff, .9);
    padding: 55px 45px 60px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 21.5%;
    display: none;

    @include laptop {
      display: block;
    }


    h2 {
      color: $brand-primary;
    }

    .location {
      width: 100%;
      background-color: #bd7c23;
      padding: 50px 50px 20px;
      position: relative;

      &:before {
        background-color: #bd7c23;
        border: 5px solid #fff;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: FontAwesome;
        font-size: 38px;
        content: '\f041';
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
      }

      p.address, p.title {
        font-size: 18px;
        line-height: 20px;
      }

      a.directionLink {
        font-size: 16px;
        font-weight: 500;
        color: #fad794;

        .fa {
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }

    .btn {
      font-size: 25px;
      width: 100%;
      margin-top: 40px;
    }
  }
}


.footer-cta {
  padding: 50px 0;
  position: relative;
  background-image: url('../images/bg_footer_cta.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 60%;
  text-align: center;

  @include laptop {
    padding: 90px 0;
    background-position: center 79%;
  }

  &:before {
    content: '';
    @include stretch;
    background-color: rgba($brand-primary, .4);
  }

  h1 {
    font-size: 40px;
    line-height: 42px;
    font-weight: 500;
    color: $off-white;

    @include laptop {
      font-size: 50px;
      line-height: 55px;
    }
  }

  .btn {
    margin: 30px 0 0;
    width: 270px;

    @include tablet {
      margin: 30px 15px 0;
    }
  }
}


.footer {
  padding: 50px 0;
  background-color: $brand-primary;
  text-align: center;

  @include tablet {
    text-align: left;
  }

  @include laptop {
    padding: 85px 0;
  }

  p, a {
    color: $white;
    font-size: 17px;
    line-height: 20px;

    @include desktop {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .logo {
    display: block;
    margin: 0 auto 10px;
    width: 200px;

    @include tablet {
      width: 251px;
      margin: 0 auto;
    }
  }

  .contact-info {
    margin-top: 5px;
    text-align: center;

    @include laptop {
      text-align: left;
    }

    p {
      font-weight: 400;
      margin: 0 0 10px;
    }
  }

  .footer-nav-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    margin: 30px 0 0;

    @include phablet {
      flex-direction: row;
    }

    @include laptop {
      margin: 0 0 0 -15px;
    }

    .menu-footer-navigation-one-container,
    .menu-footer-navigation-two-container,
    .menu-footer-navigation-three-container,
    .menu-footer-navigation-four-container {
      flex: 0 0 100%;

      @include tablet {
        flex: 0 0 auto;
      }

    }

    ul.footer-nav {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        margin-bottom: 12px;

        a {
          font-weight: 700;

          &:hover {
            border-bottom: 2px solid $white;
          }
        }
      }
    }
  }
}


.links-copy {
  background-color: $white;
  padding: 5px;

  a, p {
    font-size: 12px;
    font-weight: 300;
    color: $brand-primary;
    margin: 0;
  }

  .left-text, .right-text {
    text-align: center;
  }

  @include laptop {
    .left-text {
      text-align: left;
    }

    .right-text {
      text-align: right;
    }
  }
}
