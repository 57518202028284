.section {
  position: relative;
  padding: 50px 0;
  text-align: center;

  @include laptop {
    padding: 100px 0;
  }

  p {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
  }

  h2 {
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 50px;

    @include laptop {
      font-size: 55px;
    }
  }

  h3 {
    font-size: 26px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  // Global Styles for homepage two column and three column flex layout
  ul.two-col, ul.three-col {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 90px;


      @include desktop {
        margin-bottom: 0;
      }

      .block {
        width: 100%;
        position: relative;
        padding: 50px;

        .icon {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }

        h3 {
          font-size: 35px;
          margin-top: 30px;
        }

        &.tobacco {
          margin-top: -20px;

          @include laptop {
            margin: 0;
          }
        }

        &.vaping {
          margin-top: 15px;

          @include laptop {
            margin: 0;
          }
        }
      }

      .btn {
        width: 100%;
        margin-top: 15px;
      }
    }
  }

  // Styles for homepage two column flex layout
  .two-col {
    justify-content: center;

    li {
      flex: 0 0 100%;
      margin: 0 32px;

      @include laptop {
        flex: 0 0 400px;
      }

      @include desktop {
        flex: 0 0 460px;
      }
    }
  }

  // Styles for homepage two column flex layout
  .three-col {
    justify-content: space-around;

    @include desktop {
      justify-content: space-between;
    }

    li {
      flex: 0 0 100%;

      @include laptop {
        flex: 0 0 400px;
      }

      @include desktop {
        flex: 0 0 325px;
      }


      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}



.why-us-section {
  padding: 50px 0;
  background-color: $white;
  color: $brand-primary;

  @include laptop {
    padding: 90px 0;
  }

  .feature {
    margin-bottom: 30px;

    @include laptop {
      margin: 0;
    }

    .icon {
      display: block;
      height: 80px;
    }

    h3 {
      color: $yellow;
    }
  }


  .btn {
    margin-top: 20px;

    @include laptop {
      margin-top: 50px;
    }
  }
}


.smoking-accessories-section {
  background-image: url('../images/bg_smoking_accessories.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &:before {
    content: '';
    @include stretch;
    background-color: rgba($white, .7);
  }

  h2 {
    color: $brand-primary;
    margin-bottom: 90px;
  }

  .two-col {

    @include tablet {
      margin-bottom: 140px;
    }

  }

  .three-col {
    .block {
      min-height: 230px;
    }
  }

  .block {
    background-color: $brand-primary;

    &.border-box:before {
      border-color: $white;
    }

    .icon {
      &.cigarettes, &.cigars, &.tobacco, &.accessories {
        top: -53px;
      }

      &.cigars, &.tobacco, &.vaping, &.accessories {
        margin-left: -15px;
      }

      &.vaping {
        top: -90px;
      }
    }

    h3 {
      color: $yellow;
    }

    p {
      color: $white;
    }
  }
}


.beer-wine-section {
  background-image: url('../images/bg_alcohol.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &:before {
    content: '';
    @include stretch;
    background-color: rgba($brand-primary, .8);
  }

  h2 {
    color: $white;
    margin-bottom: 130px;
  }

  ul.three-col {
    margin: 0;

    li {
      margin-bottom: 110px;

      @include desktop {
        margin-bottom: 0;
      }
    }
  }

  .block {
    background-color: $gray;

    &.border-box:before {
      border-color: $brand-primary;
    }

    .icon {

      &.beer {
        top: -70px;
      }

      &.wine {
        top: -80px;
      }

      &.alcohol {
        top: -85px;
      }
    }

    h3 {
      color: $brand-primary;
    }

    p {
      color: $black;
    }
  }

  .btn {
    margin-top: 50px;
  }
}


.age-gate-submit {
  padding: 8px 14px;
  margin-bottom: 10px;
  background-color: #d8a445;
  color: white;
  text-transform: uppercase;
  font-weight: 800;
  border: 1px solid #d8a445;
}

.age-gate-form {
  background-color: rgba(90, 16, 1, 1);
  border: 2px solid white;
}