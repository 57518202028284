.sideNav {
    width: $sideNavWidth;
    #{$sideNavDirection}: -$sideNavWidth;
    position: fixed;
    z-index: 1000;
    height: 100%;
    top: 0;
    background: $brand-primary;
    transition: all 0.3s ease;

    a {
        display: block;
        color: #fff;
        font-size: 15px;
        border-bottom: 1px solid #111;
        padding: 20px;
    }

    &.sideNav-open {
        #{$sideNavDirection}: 0px;
    }
}

/* classes applied to the body */
.sideNavBody {
    overflow-x: hidden;
    position: relative;
    #{$sideNavDirection}: 0;
    transition: all 0.3s ease;
}

.sideNavBody-push {
    #{$sideNavDirection}: $sideNavWidth;
}
