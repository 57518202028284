#store-locator-container
{
float: left;
margin-left: 20px;
width: 875px;
font: normal 12px Arial, Helvetica, sans-serif;
color: #333;
}

#store-locator-container p, #store-locator-container label, #store-locator-container input{
    font-size: 16px;
}

#store-locator-container #submit{
    font-weight: 700;
    width:102px;
    height:27px;
    margin-top:3px;
}

#page-header
{
float: left;
}

#form-container
{
clear: left;
float: left;
margin-top: 15px;
width: 100%;
}

#map-container
{
clear: left;
float: left;
margin-top: 35px;
height: 530px;
width: 875px;
box-shadow: 0 0 3px #3e3e3e;
}



#map-container a
{
color: #e76737;
text-decoration: none;
}

#map-container a:hover, #map-container a:active
{
text-decoration: underline;
}

#map-container .custom-marker
{
width: 32px;
height: 37px;
color: #fff;
background: url(../images/custom-marker.png) no-repeat;
    padding: 3px;
    cursor: pointer;
}

#loc-list
{
float: left;
width: 240px;
height: 530px;
overflow: auto;
}

#loc-list ul
{
display: block;
clear: left;
float: left;
list-style: none;
margin: 0;
padding: 0;
}

#loc-list .list-label
{
float: left;
margin: 10px 0 0 6px;
padding: 2px 3px;
width: 17px;
text-align: center;
background: #451400;
color: #fff;
font-weight: bold;
}

#loc-list .list-details
{
float: left;
margin-left: 6px;
width: 165px;
}

#loc-list .list-content
{
padding: 10px;
}

#loc-list .loc-dist
{
font-weight: bold;
font-style: italic;
color: #8e8e8e;
}

#loc-list li
{
display: block;
clear: left;
float: left;
margin: 6px 10px;
cursor: pointer;
width: 200px;
border: 1px solid #fff; /* Adding this to prevent moving li elements when adding the list-focus class*/
}

#loc-list .list-focus
{
border: 1px solid rgba(82,168,236,0.9);
-moz-box-shadow: 0 0 8px rgba(82,168,236,0.7);
-webkit-box-shadow: 0 0 8px rgba(82,168,236,0.7);
box-shadow: 0 0 8px rgba(82,168,236,0.7);
transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s;
}

#map-container .loc-name
{
color: #AD2118;
font-weight: bold;
}

#search-form
{
clear: left;
float: left;
height: 60px;
}

#form-input
{
float: left;
margin-top: 3px;
}

#form-input label
{
font-weight: bold;
}

#address
{
margin: 0 0 0 10px;
}

#submit-btn
{
float: left;
cursor: pointer;
margin-left: 10px;
}

#map-container #map
{
float: left;
width: 635px;
height: 530px;
}

/* Modal window */

#overlay
{
     position: fixed;
     left: 0px;
     top: 0px;
     width:100%;
     height:100%;
     z-index: 10000;
     background: url(../images/overlay-bg.png) repeat;
}

#modal-window
{
position: absolute;
left: 50%;
    margin-left: -460px; /* width divided by 2 */
    margin-top: 60px;
    width: 920px;
height: 590px;
z-index: 10010;
background: #fff;
border-radius: 10px;
box-shadow: 0 0 10px #656565;
}

#modal-content
{
float: left;
padding: 0 22px; /* there's already a margin on the top of the map-container div */
}

#close-icon
{
position: absolute;
top: -6px;
right: -6px;
width: 18px;
height: 18px;
cursor: pointer;
background: #2c2c2c url(../images/close-icon.png) 3px 3px no-repeat;
border: 1px solid #000;
border-radius: 3px;
box-shadow: 0 0 3px #656565;
}


/* The following is for the geocode page and not the store locator */

#geocode-result
{
clear: left;
float: left;
margin-top: 30px;
width: 100%;
}
