// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #5a1001;
$yellow:                #d8a445;
$off-white:             #f1f1f1;
$gray:                  #efe7e6;
$white:                 #ffffff;
$black:                 #252122;

// Fonts
$primary-font:          "niveau-grotesk",sans-serif; // ExtraLight - 200, Light - 300, Regular - 400, Medium - 500, Bold - 700, Black - 900


//Side-Nav
$sideNavWidth: 260px;
$sideNavDirection: (right);

$button_width: 40px;     // width of button area
$button_height: 90px;     // height of button area
$bar_thickness: 6px;     // thickness of button bars
$bar_roundness: 0;
$button_pad: 0px;      // left/right padding between button area and bars.
$button_bar_space: 8px;     // spacing between button bars
$button_transistion_duration: 0.3s;  // transition duration
$hamburger_normal: #fff;
$hamburger_active: #fff;


// Breakpoint variables
$width_mobile: 480px;
$width_phablet: 544px;
$width_tablet: 768px;
$width_laptop: 992px;
$width_desktop: 1200px;

/*
 * Mixins
 */
// Media query mixins
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }


@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}


@mixin stretch($offset: 0) {
  position: absolute;
  top: #{$offset};
  right: #{$offset};
  left: #{$offset};
  bottom: #{$offset};
}


@mixin smooth-fonts() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin letter-spacing($letter-spacing: 0) {
   letter-spacing: calc(#{$letter-spacing} * 0.001em);
 }


 // Flexbox mixin - prevents most IE 11 issues
 @mixin flex($grow: 0, $shrink: 0, $basis: 100%) {
   flex: #{$grow} #{$shrink} #{$basis};
   max-width: #{$basis};
 }
