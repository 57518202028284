html {
  display: flex;
}

body {
  min-height: 100vh;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  font-family: $primary-font;
  @include smooth-fonts;
}

#wrapper {
  flex: 1 1 auto;
}

img {
  max-width: 100%;
}

a {

  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

.bordered-section {
  height: 100%;

  &:before, &:after {
    content: '';
    width: 100%;
    height: 3px;
    background-color: $white;
    position: absolute;
    left: 0;
  }

  &:before {
    top: 3px;
  }

  &:after {
    bottom: 3px;
  }
}

.border-box {
  background-color: $brand-primary;
  position: relative;

  &:before {
    content: '';
    @include stretch;
    border: 2px solid $white;
    margin: 15px;
  }
}


#av-overlay-wrap {
  background: rgba($black, .95) !important;

  #av-overlay {
    color: $white;
    text-align: center;
    padding: 60px 30px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    max-height: 420px;
    top: 50px;

    @include tablet {
      width: 75%;
      max-width: auto;
      left: auto;
      transform: none;
    }

    @include laptop {
      width: 40%;
      padding: 60px;
    }

    &:before {
      content: '';
      @include stretch;
      margin: 15px;
      border: 2px solid $white;
      z-index: -1;
    }

    h1 {
      margin: 0;
      font-size: 32px;
      font-weight: 700;
    }

    p {
      font-size: 22px;
      font-weight: 300;
      margin: 20px 0 25px;
    }

    label[for="av_verify_remember"] {
      display: none;
    }

    .submit {
      margin: 30px 0 0;
    }

    #av_verify {
      border: 0;
      background-color: $yellow;
      color: $white;
      font-size: 18px;
      font-weight: 600;
      padding: 8px 30px;
      cursor: pointer;
      transition: ease-in-out all .2s;

      &:hover {
        background-color: darken($yellow, 10);
      }
    }

    .error {
      color: $black;
    }
  }
}

.simple-gallery {
    .simple-gallery-img {
        padding-top: 15px;
        padding-bottom: 15px;

        img {
            height: auto;
            width: 100%;
        }
    }
}
