a.btn {
  padding: 13px 40px 15px;
  background-color: $yellow;
  color: $white;
  border-radius: 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  min-width: 227px;

  &:hover {
    background-color: darken($yellow, 10);
  }

  &.large {
    font-size: 22px;

    @include laptop {
      font-size: 25px;
    }
  }

}
