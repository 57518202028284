// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}


.gform_wrapper {
  .label-hidden {
    .gfield_label {
      display: none;
    }
  }

  .gform_page_fields {
    .gfield {
      margin: 10px 0 0;

      .ginput_container_radio {
        margin: 0 0 25px 0;
      }
    }
  }


  input, textarea {
    border: 1px solid #ccc;
  }

  input[type="submit"], input[type="button"] {
    background-color: $yellow;
    font-family: $primary-font;
    color: $white;
    border: 0;
    padding: 12px 30px;
    cursor: pointer;
    transition: ease-in-out all .2s;

    &:hover {
      background-color: darken($yellow, 10);
    }
  }
}
